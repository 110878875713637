
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.root {
  background: $yellow-1;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5rem;
  transition: 350ms ease;
  padding: 18px 36px;
  border-radius: 8px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  border: none;
  .loading {
    margin-left: 15px;
    width: 24px !important;
    height: 24px !important;
  }

  &:hover,
  &:focus {
    background: $yellow-1;
    border: none;
    outline-width: 0;
  }

  &:disabled {
    background-color: $yellow-5;
    color: $yellow-6;
    pointer-events: none;
  }

  /* PRIMARY */
  &.primary {
    background-color: $yellow-1;
    color: white;

    &:hover,
    &:focus {
      background-color: $yellow-2;
    }

    &:disabled {
      background-color: $yellow-5;
      color: $yellow-6;
      pointer-events: none;
    }
  }

  /* SECONDARY */
  &.secondary {
    background-color: $blue-1;
    color: white;

    &:hover,
    &:focus {
      background-color: $dark-blue-1;
    }

    &:disabled {
      background-color: $blue-2;
      color: $blue-3;
      pointer-events: none;
    }
  }

  /* TRANSPARENT */
  &.transparent {
    background-color: transparent;
    border: 1.5px solid $gray-3;
    color: $gray-2;

    .loading {
      * {
        stroke: $dark-blue;
      }
    }
    &:hover,
    &:focus {
      background-color: transparent;
      border: 1.5px solid $black-2;
    }
    &:disabled {
      background-color: transparent;
      border: 1.5px solid $white-5;
      color: $white-7;
      pointer-events: none;
    }
  }
}
