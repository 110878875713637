
          @import "src/styles/_mixins.scss";
        
.dropdown {
  position: absolute;
  background: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 100%;
  max-width: 258px;
  z-index: 100;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: 50;
}
