
          @import "src/styles/_mixins.scss";
        
.container {
  h3 {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  //   background-color: red;
  padding: 50px;
  &__loader {
    // background-color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
  }
  &__partiesContainer {
    text-transform: capitalize;
    padding: 20px;
    font-size: 18px;
    text-align: center;
    &__kycAml {
      margin-top: 10px;
      display: flex;
      div {
        margin: 10px;
      }
    }
  }
}

.clipboard {
  padding: 20px;

  //   display: flex;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    box-shadow: 0px 15px 20px rgb(0 0 0 / 6%);
    height: 70px;
    width: 70px;
    color: #184273;
  }
}
