
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.profile {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  overflow-x: hidden;

  .editTitle {
    padding: 20px;
    padding-bottom: 0px;

    h1 {
      font-size: 25px;
      color: $black-2;
      font-weight: 500;
    }
  }

  .profileSettings {
    flex: 0.7;
    height: 100%;

    @media screen and (max-width: 1110px) {
      flex: 0.6;
    }

    @media screen and (max-width: 900px) {
      flex: 1;
      width: 100%;
    }

    .editTitle,
    .editContent {
      padding: 20px;
    }

    .editContent {
      .input {
        &.active {
          input:focus {
            border: 1px solid $dark-blue-2;
          }
        }

        label {
          color: $black-4;
          font-weight: 400;
          font-size: 16px;
        }

        .inputColor {
          transition: 350ms ease-in-out;

          &.active {
            color: $dark-blue-2;
          }
        }
      }

      h1 {
        font-size: 15px;
        font-weight: 500;
        color: $gray;
      }
    }
  }

  .myProfile {
    flex: 0.3;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 35px;
    overflow-x: hidden;

    @media screen and (max-width: 1110px) {
      flex: 0.4;
    }

    @media screen and (max-width: 900px) {
      flex: 1;
      width: 100%;
      margin: 0px;
      padding: 20px;
    }

    .profileContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      background: $gray-8;
      border-radius: 7px;
      padding: 20px;
      overflow-x: hidden;
    }

    .avatar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;
      width: 150px;
      background: $dark-purple;
      border-radius: 50%;
      border: 2px solid $white;
      color: $white;
      font-size: 35px;

      .pencilEdit {
        position: absolute;
        bottom: 10px;
        right: 0;
        background: $blue;
        border-radius: 50%;
        fill: $blue;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    h1 {
      font-size: 25px;
      color: $dark-purple;
      margin-top: 10px;
      font-weight: 500;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

    p {
      margin: 10px 0;
      color: $dark-blue;
      font-weight: 400;

      .svg {
        margin-right: 5px;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;

    .btn {
      max-width: 200px;
      padding: 12px;
      font-weight: 500;

      @media screen and (max-width: 900px) {
        max-width: 100%;
        margin-left: 0px;
      }
    }
  }
}
