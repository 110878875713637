
          @import "src/styles/_mixins.scss";
        
@import '/src/styles/globals.scss';
.editor_container {
  background-color: #184273;
  border-radius: 10px;
  padding: 10px;
  @include respond(phone) {
    width: 100%;
  }
  .center {
    background-color: white;
    border-radius: 10px;
    min-height: 100vh;
    margin: 16px;

    padding: 8px;
  }
  :global {
    .rdw-editor-toolbar {
      background: $dark-blue-1;
      border: none;
      padding: 12px 18px 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .rdw-option-wrapper {
      padding: 8px;
      height: 34px;
      width: 34px;
      &:hover {
        box-shadow: none;
        background: $yellow-5;
      }
    }
    .rdw-option-disabled {
      opacity: 1;
      cursor: not-allowed;
      background: $gray;
      &:hover {
        background: $gray;
      }
    }
    .rdw-option-active {
      background: $yellow;
      box-shadow: none;
    }
  }
}

.top_toolbar {
  display: flex;
  justify-content: space-between;

  padding-top: 5px;
  padding-bottom: 5px;

  width: 90%;
  margin: auto;

  @include respond(phone) {
    margin-right: 20px;
  }
  button {
    width: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }
  form {
    display: flex;
  }
}
