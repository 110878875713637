
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.root {
  height: calc(100% - 360px);
}

.amlContainer {
  padding: 30px;
  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }

  ul {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li {
      font-size: 20px;

      list-style: disc;
      margin-bottom: 2rem;
    }
  }
}

.icon {
  height: 50px;
  width: 100px;
  background: #fff;
  font-weight: 600;

  border: 1px solid #e8e8e8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 330ms ease-in-out;
  &:hover {
    box-shadow: 0px 15px 20px rgb(0 0 0 / 6%);
    transform: translateY(-0.25em);
  }
}
