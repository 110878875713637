
          @import "src/styles/_mixins.scss";
        
.root {
  .toggle {
    z-index: 1;
  }
  .options {
    z-index: 5;
    .option {
      &:hover {
        background: #f7f7f7;
      }
    }
  }
}
