
          @import "src/styles/_mixins.scss";
        
@import 'src/styles/globals.scss';

.myAccount {
  height: 100%;
  display: flex;
  flex-direction: column;

  .dropdown {
    width: 100%;
    max-width: 225px;
    margin-left: auto;
    margin-bottom: 15px;
  }

  &.loading {
    border-radius: 8px;
    background: #fff;
  }

  .myAccountInvestedTooltip {
    background: #fff;
    border: 1px solid #eee;
    padding: 10px;

    @media screen and (max-width: 600px) {
      p {
        font-size: 14px;
      }
    }

    p {
      font-weight: 400;
    }

    .type {
      color: #184273;
    }

    .price {
      color: #ffa400;
    }
  }

  h2 {
    font-size: 35px;
    color: $admin-myAccount-color;
    font-weight: 400;

    @media screen and (max-width: 600px) {
      font-size: 22px !important;
    }
  }

  .horizontalIcon {
    font-size: 28px;
    color: black;
    cursor: pointer;
    stroke-width: 3;
  }

  .myAccountValue {
    background: $admin-myAccount-background-yellow;
    border-radius: 8px;
    padding: 15px 25px;
    margin-top: 30px;

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }

    h1 {
      font-size: 60px;
      color: #fff;
      font-weight: 500;
    }

    p {
      font-size: 17px;
      color: $admin-myAccount-color;
      text-transform: uppercase;
      font-weight: 500;
    }

    @media screen and (max-width: 600px) {
      p {
        font-size: 14px;
      }

      h1 {
        font-size: 40px;
      }
    }
  }

  .myAccountChartContainer {
    margin-top: 25px;
    flex: 1;

    .myAccountValueChart {
      margin-top: 10px;
      margin-bottom: 5px;

      .first {
        margin: 0 10px;

        p {
          font-size: 17px;
          font-weight: 500;

          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }

        span {
          margin-right: 10px;
        }
      }

      .first {
        color: #ffa400;

        span {
          background: #ffa400;
        }
      }

      .chartCircle {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        @media screen and (max-width: 600px) {
          width: 17px;
          height: 17px;
        }
      }

      h1 {
        font-size: 25px;
        color: $admin-myAccount-investment-color;

        @media screen and (max-width: 600px) {
          font-size: 22px;
        }
      }
    }

    .myAccountChart {
      padding: 20px;
      width: 50%;

      @media screen and (max-width: 1300px) {
        width: 100%;
      }

      h2 {
        font-size: 16px;
        color: $admin-myAccount-investment-color;
        text-transform: uppercase;
        font-weight: 500;

        @media screen and (max-width: 600px) {
          font-size: 14px !important;
        }
      }
    }

    .myAccountChart,
    .myAccountInvestments {
      flex: 0.5;
      background: #fff;
      border-radius: 8px;

      @media screen and (max-width: 1300px) {
        margin: 0;
      }
    }

    .myAccountInvestments {
      @media screen and (max-width: 1300px) {
        margin-top: 35px;
      }

      h3 {
        color: $admin-myAccount-investment-color;
        font-size: 25px;
        padding: 20px;
        padding-bottom: 0px;
      }

      .myAccountSeeAll {
        margin: 20px 0;
        padding: 0 25px;

        p {
          color: black;
          font-size: 18px;
        }

        span {
          display: flex;
          align-items: center;
          color: $admin-myAccount-blue-color;
          cursor: pointer;
          font-size: 16px;

          .icon {
            margin-left: 5px;
            stroke-width: 3;
          }
        }

        @media screen and (max-width: 600px) {
          p {
            font-size: 16px;
          }

          span {
            font-size: 14px;
          }
        }
      }

      @media screen and (max-width: 600px) {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}

.myAccountInvestmentsTable {
  margin-top: 10px;
  margin-bottom: 5px;
  border-collapse: collapse;
  width: 100%;
  overflow: scroll;

  th {
    color: black;
    font-weight: 500;
  }

  thead {
    th:first-child,
    th:last-child {
      padding: 15px 25px;
    }
  }

  @media screen and (max-width: 700px) {
    tr {
      font-size: 14px;
    }
  }

  .investment {
    border-radius: 8px;
    cursor: pointer;
    transition: 150ms ease;

    td {
      padding: 15px 25px;
      border-top: 1px solid rgba(0, 0, 0, 0.122);
      text-align: center;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }
}

.table {
  flex-direction: column;

  .tableContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    h2 {
      font-weight: 500;
      color: black;
      font-size: 17px;
    }

    &.tableContainerCenter {
      justify-content: center;
      align-items: center;
    }
  }
}
