
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.root {
  .label {
    color: $gray-3;
    font-size: 14px;
    font-weight: 500;
  }
  :global {
    .rselect__control {
      padding: 2px;
      border: 1px solid $gray-6;
      box-sizing: border-box;
      border-radius: 5px;
      box-shadow: none;

      &:hover {
        border: 1px solid $gray-7 !important;
        box-shadow: none;
        cursor: pointer;
        svg {
          fill: $gray-7;
        }
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
    .rselect__value-container {
      margin: 0 6px;
    }
    .rselect__control--menu-is-open {
      border: 1px solid $gray-7 !important;

      svg {
        fill: $gray-7;
      }
    }
    .rselect__option--is-selected {
      background: $white-3 !important;
      color: $black-3;
    }
    .rselect__option--is-focused {
      background: $white;
    }
    .rselect__placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $gray-2;
    }
    .rselect__single-value {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $gray-2;
    }

    .rselect__option {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
      &:hover {
        background: $white-3 !important;
        color: $black-3;
        cursor: pointer;
      }
    }
    .rselect__option--is-disabled {
      &:hover {
        background: none !important;
        cursor: auto;
        color: $white-5;
      }
    }
  }
  .container {
    width: 100%;
    margin-top: 6px;
  }
  .select_hasValue {
    padding-left: 40px;
  }
  .errorText {
    font-size: 14px;
    color: $error;
    margin-top: 4px;
    padding: 0 8px;
  }
}

.select_error {
  :global {
    .rselect__control {
      border-color: $error !important;
      box-shadow: none;
    }
  }
}
