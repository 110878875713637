
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: fit-content;

  .label {
    margin: 0 10px;
    color: #acb5bd;
    font-size: 14px;
    font-weight: 500;
  }

  .checkIconWrapper {
    width: 28px;
    height: 28px;
    background: $white;
    border: 1px solid $gray-5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 4px;
    transition: 250ms ease;

    .checkIcon {
      stroke: $white;
      stroke-width: 5px;
      opacity: 0;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  input:checked + .checkIconWrapper {
    background: $yellow-3;
    border: 1px solid transparent;

    .checkIcon {
      opacity: 1;
    }
  }

  &.checkboxDisabled {
    pointer-events: none;

    .checkIconWrapper {
      background: $gray-5;
    }

    p {
      color: $gray-5;
    }
  }

  &:hover {
    .checkIconWrapper {
      background: rgba(204, 204, 204, 0.204);
    }
  }
}
