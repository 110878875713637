
          @import "src/styles/_mixins.scss";
        
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Remove alert icon in verify email componenet when loading is true

.loading_verifyAlert {
  .MuiAlert-icon {
    display: none !important;
  }
}

.input_investment {
  padding: 5px 10px;
  outline-width: 0;
  border: 1px solid #e2e8f0;
  opacity: 0;
  width: 0px;
  max-width: 550px;
}

.input_investment.active {
  opacity: 1;
  width: 100%;
}

.input_investment:focus,
.input_investment:active {
  border: 1px solid transparent;
  --tw-ring-color: #1e2a3b !important;
}

.Toastify {
  .icon {
    font-size: 25px !important;
  }

  p {
    margin-left: 10px !important;
  }

  button {
    outline-width: 0;
  }
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  background: #ebf9f6 !important;
  color: #37c7a4 !important;

  .Toastify__progress-bar {
    background: #37c7a4 !important;
  }
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  background: #feecec !important;
  color: #f03d3e !important;

  .Toastify__progress-bar {
    background: #f03d3e !important;
  }
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--info {
  background: #c5cfdc !important;
  color: #2b527f !important;

  .Toastify__progress-bar {
    background: #2b527f !important;
  }
}

.Toastify__toast-icon {
  display: none !important;
}
