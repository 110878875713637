
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.responsiveNavbar {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  z-index: 10;
  transform: translateX(-100%);
  transition: 350ms ease;

  @media screen and (max-width: 600px) {
    display: flex;
  }

  &.active {
    transform: translateX(0%);
  }

  .logo {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .button {
    width: 100%;
    outline-width: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;
    padding: 10px;
    border-radius: 8px;
    transition: 350ms ease;

    &:hover {
      background: $dark-blue;
      color: #fff;
      font-weight: 600;

      .customIcon {
        path {
          fill: #fff !important;
        }
      }
    }

    svg {
      margin-right: 15px;
    }
  }

  .button.active {
    background: $dark-blue;
    color: #fff;
    font-weight: 600;

    .customIcon {
      path {
        fill: #fff !important;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  pointer-events: none;
  opacity: 0;
  transition: 350ms ease;
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
}
