
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.toggleButtonView {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  position: relative;

  &.toggleButtonDisabled {
    pointer-events: none;

    span {
      background: $gray-5;
      border: 1px solid transparent;

      .circleToggler {
        background: $gray-4;
      }
    }

    p {
      color: $gray-5;
    }
  }

  span {
    width: 52px;
    height: 28px;
    border: 1px solid $gray-5;
    border-radius: 16px;
    position: relative;
    display: flex;
    align-items: center;
    transition: 500ms ease;

    .circleToggler {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 16px;
      margin-left: 1px;
      background: $gray-5;
      transition: all 500ms ease;
      transform: translateX(0%);
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  input:checked + span {
    background: $yellow-3;
    border: 1px solid $yellow-3;

    .circleToggler {
      background: $white;
      margin-left: 0;
      margin-right: 1px;
      transform: translateX(105%);
    }
  }

  p {
    margin: 0 10px;
    color: $gray-1;
    font-size: 16px;
  }
}
