
          @import "src/styles/_mixins.scss";
        
@import '/src/styles/globals.scss';

.Admin {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $admin-background-color;

  .AdminView {
    position: relative;
    display: flex;
    flex: 1;

    .children {
      padding: 24px;
      width: calc(100vw - 360px);
      margin-left: auto;
      overflow: hidden;

      @media screen and (max-width: 1380px) {
        width: 100%;
        margin-left: 9rem;
      }

      @media screen and (max-width: 600px) {
        padding: 30px 15px;
        margin-left: 0;
        height: 100%;
      }
    }
  }
}
