
          @import "src/styles/_mixins.scss";
        
@import 'src/styles/globals.scss';

.root {
  h2 {
    font-weight: 500;
    color: black;
  }

  p {
    color: $gray-1;
    margin-top: 5px;
  }
}
