
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.root {
  display: flex;
  flex-direction: column;
  .inputWrapper {
    display: flex;
    position: relative;
    width: 100%;
    &.withLabel {
      margin-top: 5px;
    }
  }
  .input {
    color: $gray-2;
    background: $white;
    border: 1px solid $gray-6;
    border-radius: 8px;
    font-size: 15px;
    padding: 12px;
    transition: all 0.2s ease-in;
    width: 100%;
    &:focus {
      outline: none;
      border: 1px solid $yellow-1;
    }
    &:disabled {
      cursor: not-allowed;
      background: $gray-6;
    }
    &.error {
      border-color: $error !important;
    }
  }
  .label {
    color: $gray-3;
    font-size: 14px;
    font-weight: 500;
  }
}
