
          @import "src/styles/_mixins.scss";
        
.container {
  padding: 50px;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
  &__tradeId {
    font-size: 23px;
  }
  &__title {
    padding: 10px;
  }
  &__amountTitle {
    font-size: 18px;
    padding: 10px;
  }
  &__amount {
    color: green;
    font-size: 20px;
    span {
    }
  }

  form {
    input {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    label {
      margin-top: 10px;
      // background-color: red;
      text-align: left;
    }
  }
}
