
          @import "src/styles/_mixins.scss";
        
.container {
  h3 {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  //   background-color: red;
  padding: 50px;
  &__loader {
    // background-color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
  }
  &__partiesContainer {
    text-transform: capitalize;
    padding: 20px;
    font-size: 18px;
    text-align: center;
    &__kycAml {
      margin-top: 10px;
      display: flex;
      div {
        margin: 10px;
      }
    }
  }
}
