
          @import "src/styles/_mixins.scss";
        
.image_button {
  // background-color: red;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;

  &__container {
    text-align: center;
    padding: 8px;
    height: 34px;
    width: 40px;
    border: 1px solid #f1f1f1;
    /* padding: 5px; */
    /* min-width: 25px; */
    /* height: 20px; */
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    img {
      object-fit: cover;
      height: 25px;
      width: 30px;
    }
  }
  &__dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 3px 3px 5px #bfbdbd;
    //these two must be in sync size
    display: flex;
    // justify-content: space-around;
    bottom: -200px;
    height: 200px;
    width: 200px;
    left: 3px;
    border-radius: 10px;
    z-index: 2;
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      form {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }
      h2 {
        font-size: 18px;
        color: #184273;
      }

      .input_wrapper {
        // background-color: red;
        width: 90%;
        input {
          height: 40px;
        }
      }

      .button {
        height: 30px;
      }
    }
  }
}
