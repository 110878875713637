
          @import "src/styles/_mixins.scss";
        
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;

  &__right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &__left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &__up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &__down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
