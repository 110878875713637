
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.root {
  display: flex;
  flex-direction: column;
  .inputWrapper {
    display: flex;
    position: relative;
    width: 100%;
    &.withLabel {
      margin-top: 5px;
    }
  }
  .input {
    color: $gray-2;
    background: $white;
    border: 1px solid $gray-6;
    border-radius: 8px;
    font-size: 15px;
    padding: 12px;
    transition: all 0.2s ease-in;
    width: 100%;
    &:focus {
      outline: none;
      border: 1px solid $yellow-1;
    }
    &:disabled {
      cursor: not-allowed;
      background: $gray-6;
    }
    &.error {
      border-color: $error !important;
    }
    &.withLeftIcon {
      padding-left: 42px;
    }
    &.withRightIcon {
      padding-right: 42px;
    }
  }
  .label {
    color: $gray-3;
    font-size: 14px;
    font-weight: 500;
  }
  .leftIcon {
    position: absolute;
    left: 12px;
    color: $blue-4;
    top: 50%;
    transform: translateY(-50%);
  }
  .rightIcon {
    position: absolute;
    right: 12px;
    color: $blue-4;
    top: 50%;
    transform: translateY(-50%);
  }
  .inputLoading {
    * {
      stroke: $gray-2;
    }
  }
  .errorText {
    font-size: 14px;
    color: $error;
    margin-top: 4px;
    padding: 0 8px;
  }

  .checkbox {
    position: relative;
    width: 32px;
    height: 18px;
    margin-bottom: -2px;

    input:checked + .toggle {
      background: $yellow-1 !important;
    }

    input:checked + .toggle:before {
      transform: translateX(15px);
    }

    .toggle {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #bcccdc;
      transition: 0.4s;
      border-radius: 50px;
    }

    .toggle::before {
      position: absolute;
      content: '';
      height: 10px;
      width: 10px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50px;
    }
  }
}
