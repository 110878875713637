
          @import "src/styles/_mixins.scss";
        
@import '/src/styles/globals.scss';

.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: $admin-navbar-background-color;
  color: #fff;
  padding: 10px 15px;
  position: sticky;
  top: 0;
  z-index: 2;

  .menuIcon {
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }

  .dropdown {
    top: 50px;
    right: 0;
    width: 200px;

    &.dropdownNotification {
      width: 350px;
      margin-left: -150px;
      top: 40px;
    }

    .dropdownItem {
      display: flex;
      align-items: center;
      padding: 15px;
      transition: 250ms ease;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }

      p {
        color: black;
        font-weight: 400;
      }

      .blue {
        color: $blue;
      }
      .red {
        color: $red;
      }
    }
  }

  .bell {
    font-size: 20px;
    fill: $admin-navbar-light-gray-color;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .profile {
    position: relative;

    @media screen and (max-width: 600px) {
      display: none;
    }

    p {
      font-weight: 700;
    }

    .avatar {
      height: 40px;
      width: 40px;
      background: $admin-navbar-background-color;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 15px;
      margin-right: 10px;
      font-size: 15px;
      font-weight: 700;
      background-size: cover;
      background-position: center;
      text-transform: uppercase;
    }

    .chevronDown {
      margin-right: 25px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .icon {
    font-size: 22px;
    margin-right: 15px;
    cursor: pointer;
  }

  .boxabl {
    margin-left: 25px;
    transition: 50ms ease;

    &:hover {
      opacity: 0.5;
    }

    @media screen and (max-width: 600px) {
      margin-left: 0px;
    }
  }
}
