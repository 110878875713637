
          @import "src/styles/_mixins.scss";
        
@import '/src/styles/globals.scss';

.sideBar {
  display: block;
  padding: 24px 32px;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 60px;
  width: 360px;
  overflow-y: auto;

  &.fixed {
    top: 0;
  }

  @media screen and (max-width: 1380px) {
    width: fit-content;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }

  .icon,
  svg {
    font-size: 25px;
    margin: 0 10px;
  }

  img {
    height: 24px;
    width: 24px;
    margin: 0 10px;
  }

  .sidebarItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    width: 100%;
    margin-bottom: 6px;
    padding: 12px;
    border-radius: 8px;
    outline-width: 0;
    transition: 350ms ease;

    &:hover {
      background: #fff;
      padding-left: 25px;
    }

    @media screen and (max-width: 1380px) {
      &:hover {
        padding-left: 12px;
        background: #fff;
      }
    }

    p {
      margin-left: 4px;
      margin-right: 13px;
      color: $admin-sidebar-color;

      @media screen and (max-width: 1380px) {
        display: none;
      }
    }
  }

  button.active {
    background: #fff;
    font-weight: 600;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
    border: 1px solid #e8e8e8;

    p {
      color: $admin-sidebar-color;
    }
  }
}
