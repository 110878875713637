
          @import "src/styles/_mixins.scss";
        
@import '/src/styles/globals.scss';

.featured_link {
  // background-color: red;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;

  &__container {
    text-align: center;
    padding: 8px;
    height: 34px;
    width: 34px;
    border: 1px solid #f1f1f1;
    /* padding: 5px; */
    /* min-width: 25px; */
    /* height: 20px; */
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
  }
  &__dropdown {
    position: absolute;
    background-color: green;
    //these two must be in sync size
    display: flex;
    // justify-content: space-around;
    bottom: -200px;
    height: 200px;
    width: 200px;
    left: 3px;
    z-index: 2;
  }
}

.editor_container {
  background-color: #184273;
  border-radius: 10px;
  padding: 10px;
  @include respond(phone) {
    width: 100%;
  }
  .center {
    background-color: white;
    border-radius: 10px;
    min-height: 100vh;
    margin: 16px;

    padding: 8px;
  }
  :global {
    .rdw-editor-toolbar {
      background: $dark-blue-1;
      border: none;
      padding: 12px 18px 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .rdw-option-wrapper {
      padding: 8px;
      height: 34px;
      width: 34px;
      &:hover {
        box-shadow: none;
        background: $yellow-5;
      }
    }
    .rdw-option-disabled {
      opacity: 1;
      cursor: not-allowed;
      background: $gray;
      &:hover {
        background: $gray;
      }
    }
    .rdw-option-active {
      background: $yellow;
      box-shadow: none;
    }
  }
}

.top_toolbar {
  display: flex;
  justify-content: space-between;

  padding-top: 5px;
  padding-bottom: 5px;

  width: 90%;
  margin: auto;

  @include respond(phone) {
    margin-right: 20px;
  }
  button {
    width: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }
  form {
    display: flex;
  }
}
