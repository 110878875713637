
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.totalInvestments {
  background: $yellow;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 35px;
  margin-right: 25px;

  @media screen and (max-width: 950px) {
    margin-right: 0px;
  }

  &.noMargin {
    margin-right: 0;
  }

  p {
    color: $dark-gray;
    text-transform: uppercase;
    font-weight: 500;
  }

  span {
    font-size: 50px;
    font-weight: 500;
    color: #fff;
  }

  @media screen and (max-width: 950px) {
    p {
      font-size: 14px;
    }

    span {
      font-size: 40px;
    }
  }
}
