
          @import "src/styles/_mixins.scss";
        
@import '../../styles/globals.scss';

.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 17px;
  width: fit-content;
  border-radius: 200px;
  cursor: pointer;
  font-weight: 500;

  &.pink {
    background: $error-2;
    color: $black-2;
  }

  &.blue {
    background: $dark-blue-1;
    color: $white;
  }

  &.black {
    background: $black-2;
    color: $white;
  }

  &.white {
    background: $white;
    color: $yellow-1;
    border: 1px solid $gray-6;
  }

  span {
    font-size: 15px;
  }
}
