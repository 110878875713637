/* Global Variables */
$blue: #5181b8;
$dark-blue: #184273;
$red: #e64646;
$gray: #acb5bd;
$dark-gray: #495057;
$yellow: #ffa400;

/* Yellow Colors */
$yellow-1: #ffa400;
$yellow-2: #e89729;
$yellow-3: #ffbb56;
$yellow-4: #fab14e;
$yellow-5: #fed180;
$yellow-6: #fee8bf;

/* Blue Colors */
$dark-blue: #243b53;
$dark-blue-1: #184273;
$dark-blue-2: #184273;
$blue-1: #2342c0;
$blue-2: #8ba0b9;
$blue-3: #c5cfdc;
$blue-4: #2b527f;

/* Purple Colors */
$dark-purple: #0d2347;

/* Error Colors */
$error: #f03d3e;
$error-1: #e64646;
$error-2: #f784ad;

/* Success Colors */
$success: #37c7a4;

/* Black Colors */
$black: #000000;
$black-1: #252525;
$black-2: #212429;
$black-3: #151b26;
$black-4: #424242;

/* Gray Colors */
$gray-1: #505050;
$gray-2: #495057;
$gray-3: #acb5bd;
$gray-4: #b5b5b5;
$gray-5: #e1e1e1;
$gray-6: #dde2e5;
$gray-7: #6f7782;
$gray-8: #edeef0;

/* White Colors */
$white: #fff;
$white-1: #fff7ed;
$white-2: #feecec;
$white-3: #f8f9fa;
$white-4: #faebeb;
$white-5: #e6e9eb;
$white-6: #ebf9f6;
$white-7: #c8cbcd;

/* Custom Colors */
$skin-color: #99a2ad;

// Admin
$admin-background-color: #e5e5e5;

// Admin Navigation Bar
$admin-navbar-background-color: #184273;
$admin-navbar-gray-color: #83a0be;
$admin-navbar-input-color: #8fadc8;
$admin-navbar-light-gray-color: #dde2e5;

// Admin Side Bar
$admin-sidebar-color: #184273;

// My Account
$admin-myAccount-color: #495057;
$admin-myAccount-background-yellow: #ffa400;
$admin-myAccount-investment-color: #acb5bd;
$admin-myAccount-gray-color: #818c99;
$admin-myAccount-blue-color: #2342c0;
