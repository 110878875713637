
          @import "src/styles/_mixins.scss";
        
@import 'src/styles/globals.scss';

.attachmentsTable {
  table {
    border: 1px solid $gray;
    th {
      background: $dark-blue-1;
      color: $white;
    }
  }
}
