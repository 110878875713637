
          @import "src/styles/_mixins.scss";
        
@import 'styles/globals.scss';

.error {
  padding: 24px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);

  .icon {
    color: $red;
  }

  h1 {
    font-size: 25px;
    font-weight: 500;
    color: black;
    margin: 5px 0;
  }

  p {
    color: $gray;
    margin-bottom: 5px;
  }
}
