
          @import "src/styles/_mixins.scss";
        
.container {
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 25px;
  }
}

.buttons {
  display: flex;
  button {
    margin: 20px;
  }
}
